import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';
import { website } from '@getpopsure/private-constants';
import chatShield from '../img/chat-shield.svg';

const AdjustPolicy = () => {
  const { t } = useTranslation();
  return (
    <div className="p-body public-signup-center-element">
      <div className="wmx8 mx-auto d-flex fd-column ai-center">
        <img src={chatShield} alt="" />
        <h1 className="p-h1 mt16 ta-center" data-cy="blocker-title">
          {t('page.blocker.adjustPolicy.title', 'Reach out to us')}
        </h1>
        <p className="p-p mt8 ta-center">
          {t(
            'page.blocker.adjustPolicy.description',
            'You can contact us if you’d like to make changes to your public health policy or application and we’ll help you through the process.'
          )}
        </p>
        <a className="mt16 wmn3 ws2 mr8 p-btn--primary" href={website.support}>
          {t('page.blocker.contactus.action', 'Contact us')}
        </a>
      </div>
    </div>
  );
};

export default AdjustPolicy;
