import axios, { AxiosError, AxiosInstance } from 'axios';
import Session, { CSRF_TOKEN_KEY } from '@getpopsure/session';
import { language } from '@getpopsure/i18n-react';

import { QuestionnaireState } from 'reducers/questionnaire';
import { serializeQuestionnaire } from './serializer';
import { Provider, provider } from 'util/environment';
import {
  Policy,
  PostCheckoutRequest,
  PostCheckoutResponse,
  User,
} from 'models';
import { v4 as uuidv4 } from 'uuid';
import { getTrackingObject } from '@getpopsure/tracker';
import { SourceTracker } from '../../tracker';
import * as Sentry from '@sentry/browser';
import { signOutUserAction } from 'actions/session';
import { store } from 'reducers';

const isDevEnv = process.env.NODE_ENV === 'development';
const apiEndpoint = process.env.REACT_APP_ENDPOINT;
const endpoint =
  isDevEnv || !apiEndpoint
    ? `${window.location.protocol}//${window.location.host}`
    : apiEndpoint;

const isRunningOnCypress = (): boolean => {
  return !!window.Cypress;
};

const instance: AxiosInstance = axios.create({
  baseURL: endpoint,
  xsrfCookieName: CSRF_TOKEN_KEY,
  xsrfHeaderName: 'csrf-token',
  withXSRFToken: true,
  withCredentials: true,
  headers: {
    'auth-version': 'v2',
    ...(isRunningOnCypress() ? { 'x-feather-e2e-tests': true } : {}),
  },
});

const handleOnAccountRefreshError = (response?: Response | void) => {
  const isSignoutRequest =
    typeof response !== 'undefined' && response?.url === '/account/signout';

  if (response?.status === 401 && !isSignoutRequest) {
    store.dispatch(signOutUserAction());

    if (Session.isAuthenticated) {
      Sentry.captureMessage(
        '[SESSION_ERROR]: Returned with a 401 while being authenticated on account refresh',
        Sentry.Severity.Error
      );
    }
  }
};

const handleSignoutError = (error: AxiosError) => {
  if (error?.response) {
    const isSignoutRequest = error.response.config.url === '/account/signout';
    if (error.response.status === 401 && !isSignoutRequest) {
      store.dispatch(signOutUserAction());

      if (Session.isAuthenticated) {
        Sentry.captureMessage(
          '[SESSION_ERROR]: Returned with a 401 while being authenticated',
          Sentry.Severity.Error
        );
      }
    }
  }

  return Promise.reject(error);
};

Session.addAxiosInterceptors(
  instance,
  handleOnAccountRefreshError,
  handleSignoutError
);

export { instance };

const providerIdMapping: { [k in Provider]: string } = {
  aok: 'AOK',
  barmer: 'BARMER',
  tk: 'TK',
  dak: 'DAK',
  bkk: 'BKK_FIRMUS',
};

export async function sendSignInEmail(email: string) {
  return instance.post('/account/signin', {
    email,
  });
}

export async function verifyCustomerEmail(email: string) {
  return instance.post<{ email: string; userExists: boolean }>(
    '/user/email_validation',
    { email }
  );
}

export async function signInWithTemporaryLoginCode(
  code: string,
  email: string
) {
  return instance.post('/account/signin/code', {
    email,
    code,
  });
}

export async function setCheckoutStarted(email: string) {
  return instance.post('/customerio/checkout_started', {
    email,
    insuranceType: 'PUBLIC_HEALTH',
  });
}

export async function postPublicHealthQuestionnaire(
  questionnaire: QuestionnaireState,
  agent?: string
) {
  return instance.post('/questionnaires/', {
    answers: serializeQuestionnaire(questionnaire),
    questionnaireType: 'PUBLIC_HEALTH',
    metadata: {
      ...SourceTracker.getTrackingMetaData(),
      ...getTrackingObject(),
      providerId: providerIdMapping[provider],
      agent,
    },
  });
}

export async function postSignature(signature: string) {
  return instance.patch('/me/broker_mandate', {
    signature,
  });
}

export async function postCheckout({
  questionnaireId,
  referralCode,
}: PostCheckoutRequest) {
  return instance.post<PostCheckoutRequest, PostCheckoutResponse>(
    '/public/checkout',
    { questionnaireId, ...(referralCode ? { referralCode } : {}) },
    {
      headers: {
        'idempotency-key': uuidv4(),
      },
    }
  );
}

export async function getUser() {
  return instance.get<User>(`/me`);
}

export async function signOutUser() {
  return instance.delete(`/account/signout`);
}

export async function createUser(user: User) {
  return instance.post('/user', {
    ...user,
    language: language(),
  });
}

export async function trackFinanceAdsConversion({
  policyId,
}: {
  policyId: string;
}) {
  return instance.post('/finance_ads/track_conversion', {
    policyId,
    financeAdsCategory: 'eng_publichealth',
  });
}

export async function getPolicies() {
  return instance.get<Policy[]>('/me/policies');
}

export async function isCustomerAffiliate(email: string) {
  return instance.post<{ email: string; affiliateExists: boolean }>(
    '/user/affiliate_validation',
    { email }
  );
}
