import { useTranslation } from '@getpopsure/i18n-react';
import React from 'react';
import { Button } from '@popsure/dirty-swan';
import { Form } from 'react-final-form';
import SelectorQuestion from 'components/questionnaire/questions/selectorQuestion';
import { useHistory } from 'react-router';
import { routes } from 'routes';

type AlreadySubmittedReason =
  | 'CHANGE_PROVIDERS'
  | 'INACTIVE_POLICY'
  | 'ADJUST_POLICY';

const AlreadyCovered = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit = (values: {
    alreadySubmittedReason: AlreadySubmittedReason;
  }) => {
    if (values.alreadySubmittedReason === 'ADJUST_POLICY') {
      history.push(routes.blocker.adjustPolicy.path);
      return;
    }

    history.push(`${routes.questionnaire.path}/name`);
  };

  return (
    <div className="p-body public-signup-center-element">
      <div>
        <div className="p-h1 mt16">
          {t(
            'page.blocker.alreadyCovered.title',
            'We found another policy in your account'
          )}
        </div>
        <div className="p-p mt8">
          <p className="p-p mb16">
            {t(
              'page.blocer.alreadyCovered.description',
              'Let’s make sure we’re sending you the right way.'
            )}
          </p>
        </div>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <SelectorQuestion
                name="alreadySubmittedReason"
                type="radio"
                values={[
                  {
                    value: 'CHANGE_PROVIDERS',
                    text: t(
                      'page.blocker.alreadyCovered.changeProviders',
                      'I want to change providers'
                    ),
                  },
                  {
                    value: 'INACTIVE_POLICY',
                    text: t(
                      'page.blocker.alreadyCovered.inactivePolicy',
                      'My policy isn’t active anymore'
                    ),
                  },
                  {
                    value: 'ADJUST_POLICY',
                    text: t(
                      'page.blocker.alreadyCovered.adjustPolicy',
                      'I need to adjust my policy'
                    ),
                  },
                ]}
              />
              <div className="mt24 d-flex jc-start ai-center f-wrap">
                <Button data-cy="continue-button" type="submit">
                  {t('page.blocker.alreadyCovered.continue.label', 'Continue')}
                </Button>
              </div>
            </form>
          )}
        ></Form>
      </div>
    </div>
  );
};

export default AlreadyCovered;
