import { Policy } from 'models';

type CUSTOMER_POLICY_STATUS =
  | 'ACTIVE_POLICIES'
  | 'PENDING_POLICIES'
  | 'NO_POLICIES';

/**
 * Fetch the customer's policies and determine
 * if they have any pending or active public health policies
 */
export const getCustomerPolicyStatus = (
  policies: Policy[]
): CUSTOMER_POLICY_STATUS => {
  const activePublicHealthPolicies = (policy: Policy) =>
    policy.type === 'PUBLIC_HEALTH' && policy.status === 'COVERED';

  const pendingPublicHealthPolicies = (policy: Policy) =>
    policy.type === 'PUBLIC_HEALTH' &&
    [
      'APPLICATION_SENT',
      'APPLICATION_RECEIVED',
      'ERR_MISSING_INFO',
      'PROCESSING',
    ].includes(policy.status);

  if (policies.find(activePublicHealthPolicies)) {
    return 'ACTIVE_POLICIES';
  }

  if (policies.find(pendingPublicHealthPolicies)) {
    return 'PENDING_POLICIES';
  }

  return 'NO_POLICIES';
};
