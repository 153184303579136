import { connect, useDispatch } from 'react-redux';
import { TranslationFn, useTranslation } from '@getpopsure/i18n-react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import { trackPageView } from '@getpopsure/analytics';

import '@popsure/dirty-swan/dist/index.css';
import '@getpopsure/cookie-consent/dist/style.css';

import Header from 'components/header';
import Questionnaire from 'containers/questionnaire';
import Blocker from 'components/pages/blocker';
import SpouseBlocker from 'components/pages/blocker/spouse';
import FamilyReunionVisaBlocker from 'components/pages/blocker/family-reunion';
import Intro from 'components/pages/intro';
import NotFound from 'components/pages/notFound';
import NextSteps from 'components/pages/nextSteps';
import { StudentNextSteps } from 'components/pages/studentNextSteps';
import Signature from 'containers/signature';
import { routes } from 'routes';
import { Provider, provider } from 'util/environment';
import { setQuestionnaireAgent } from 'actions/metadata';
import { initializeTrackers, SourceTracker, useQuery } from 'tracker';

import styles from './style.module.scss';
import { RouteComponentProps, useLocation } from 'react-router';
import { useEffect } from 'react';
import Session from '@getpopsure/session';
import { getUser } from 'services/api';
import { autofillAnswerFromUserAction } from 'actions/session';
import { CookieBanner } from '@getpopsure/cookie-consent';
import { generatePageTitle } from 'util/generatePageTitle';
import AlreadySubmittedAnApplication from './components/pages/blocker/already-submitted-an-application';
import AlreadyCovered from './components/pages/blocker/already-covered';
import AdjustPolicy from './components/pages/blocker/adjust-policy';

export const metaTitle = (t: TranslationFn): { [key in Provider]: string } => ({
  aok: t('page.root.meta.aok.title', 'Signup AOK'),
  tk: t('page.root.meta.tk.title', 'Signup TK'),
  dak: t('page.root.meta.dak.title', 'Signup DAK'),
  barmer: t('page.root.meta.barmer.title', 'Signup Barmer'),
  bkk: t('page.root.meta.bkk.title', 'Signup BKK firmus'),
});

const metaDescription = (t: TranslationFn): { [key in Provider]: string } => ({
  aok: t(
    'page.root.meta.aok.description',
    'Sign up for Germany’s #1 public health insurance fund all in English. Get expert advice with free customer service. Sign up in less than 5 minutes.'
  ),
  tk: t(
    'page.root.meta.tk.description',
    'Sign up for Germany’s #1 public health insurance fund all in English. Get expert advice with free customer service. Sign up in less than 5 minutes.'
  ),
  dak: t(
    'page.root.meta.dak.description',

    'Sign up for Germany’s #1 public health insurance fund all in English. Get expert advice with free customer service. Sign up in less than 5 minutes.'
  ),
  barmer: t(
    'page.root.meta.barmer.description',

    'Sign up for Germany’s #1 public health insurance fund all in English. Get expert advice with free customer service. Sign up in less than 5 minutes.'
  ),
  bkk: t(
    'page.root.meta.bkk.description',

    'Sign up for Germany’s #1 public health insurance fund all in English. Get expert advice with free customer service. Sign up in less than 5 minutes.'
  ),
});

const appleTouchIcon: { [key in Provider]: string } = {
  aok: '/logos/aok/logo192.png',
  tk: '/logos/tk/logo192.png',
  dak: '/logos/dak/logo192.png',
  barmer: '/logos/barmer/logo192.png',
  bkk: '/logos/bkk/logo192.png',
};

const favicon: { [key in Provider]: string } = {
  aok: 'favicon-aok.ico',
  tk: 'favicon-tk.ico',
  dak: 'favicon-dak.ico',
  barmer: 'favicon-barmer.ico',
  bkk: 'favicon-bkk.ico',
};

const View = ({
  onSetQuestionnaireAgent,
}: {
  onSetQuestionnaireAgent: (agent: string) => void;
}) => {
  const { agent, ...query } = useQuery();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const fetchUser = async () => {
      const { data: user } = await getUser();
      dispatch(autofillAnswerFromUserAction(user));
    };

    if (Session.isAuthenticated) {
      fetchUser();
    }
  }, [dispatch]);

  useEffect(() => {
    SourceTracker.setSource(query);
  }, [query]);

  useEffect(() => {
    trackPageView();
  }, [location]);

  if (agent) {
    onSetQuestionnaireAgent(agent);
  }
  const title = [metaTitle(t)[provider], generatePageTitle(location.pathname)]
    .filter(Boolean)
    .join(' - ');

  return (
    <>
      <div className={styles.root}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={metaDescription(t)[provider]} />
          <link rel="apple-touch-icon" href={appleTouchIcon[provider]} />
          <link
            rel="shortcut icon"
            href={`${process.env.PUBLIC_URL}/${favicon[provider]}`}
          />
        </Helmet>
        <Header />
        <Switch>
          <Route path="/" exact={true} component={Intro} />
          <Route
            path={routes.blocker.generic.path}
            exact={true}
            component={({ match }: RouteComponentProps) => (
              <Blocker {...match.params} />
            )}
          />
          <Route
            path={routes.blocker.spouse.path}
            exact={true}
            component={SpouseBlocker}
          />
          <Route
            path={routes.blocker.familyReunionVisa.path}
            exact={true}
            component={FamilyReunionVisaBlocker}
          />
          <Route
            path={routes.blocker.alreadySubmittedAnApplication.path}
            exact={true}
            component={AlreadySubmittedAnApplication}
          />
          <Route
            path={routes.blocker.alreadyCovered.path}
            exact={true}
            component={AlreadyCovered}
          />

          <Route
            path={routes.blocker.adjustPolicy.path}
            exact={true}
            component={AdjustPolicy}
          />
          <Route
            exact={true}
            path={routes.questionnaire.question.path}
            render={({
              match: {
                params: { questionId },
              },
            }) => {
              return <Questionnaire questionId={questionId as any} />;
            }}
          />
          <Route
            path={routes.signature.path}
            exact={true}
            component={Signature}
          />
          <Route
            path={routes.nextSteps.path}
            exact={true}
            component={NextSteps}
          />
          <Route
            path={routes.studentNextSteps.path}
            exact={true}
            component={StudentNextSteps}
          />
          <Route component={NotFound} />
        </Switch>
      </div>
      <CookieBanner onSave={initializeTrackers} />
    </>
  );
};

export default connect(
  () => ({}),
  (dispatch: any) => ({
    onSetQuestionnaireAgent: (agent: string) => {
      dispatch(setQuestionnaireAgent(agent));
    },
  })
)(View);
