import { TranslationFn, useTranslation } from '@getpopsure/i18n-react';
import { app } from '@getpopsure/private-constants';
import { provider, Provider, providerNameMapping } from 'util/environment';
import Card, { CardInfo } from 'components/card';
import { redirectUserToApp } from 'sagas';
import { useDispatch } from 'react-redux';

import styles from './style.module.scss';
import documentIcon from './img/document.svg';
import emailIcon from './img/email.svg';
import passportIcon from './img/passport.svg';
import cardIcon from './img/card.svg';

const getSteps = (provider: Provider, t: TranslationFn): Array<CardInfo> => [
  ...(provider === 'bkk'
    ? [
        {
          id: 1,
          title: t(
            'page.nextstep.step.approval.title',
            '{{provider}}’s approval',

            { provider: providerNameMapping[provider] }
          ),
          description: t(
            'page.nextstep.step.approval.bkk.description',
            'Usually within two weeks you will be notified.'
          ),
          icon: {
            src: emailIcon,
            alt: t('page.nextstep.step.approval.icon.alt', 'Email icon'),
          },
        },
      ]
    : [
        {
          id: 1,
          title: t(
            'page.nextstep.step.approval.title',
            '{{provider}}’s approval',

            { provider: providerNameMapping[provider] }
          ),
          description: t(
            'page.nextstep.step.approval.description',
            'Within 5-10 days you will get notified by email.'
          ),
          icon: {
            src: emailIcon,
            alt: t('page.nextstep.step.approval.icon.alt', 'Email icon'),
          },
        },
        {
          id: 2,
          title: t('page.step.photo.title', 'Send passport photo'),
          description:
            provider === 'tk'
              ? t(
                  'page.step.photo.description.tk',
                  'TK will send you a letter with the instructions'
                )
              : t(
                  'page.step.photo.description',
                  'Done digitally in your account.'
                ),
          icon: {
            src: passportIcon,
            alt: t('page.step.photo.icon.alt', 'Photo icon'),
          },
        },
        {
          id: 3,
          title: t('page.nextstep.card.title', 'Receive health card'),
          description: t(
            'page.nextstep.card.description',
            'By mail, within 2-3 weeks after submitting your photo.'
          ),
          icon: {
            src: cardIcon,
            alt: t('page.nexststep.card.icon.alt', 'Health card icon'),
          },
        },
      ]),
];

const NextSteps = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className={`p-body public-signup-center-element ${styles.container}`}>
      <div className="w100">
        <div className="d-flex jc-center">
          <img
            className="m-auto"
            src={documentIcon}
            alt={t('page.nextstep.icon.alt', 'Document icon')}
          />
        </div>
        <h1 className="p-h1 ta-center">
          {t('page.nextstep.title', 'The next steps')}
        </h1>
        <p className="p-p tc-grey-600 ta-center">
          {t('page.nextstep.description', 'Here’s what happens after this.')}
        </p>
        <div
          className={`mt24 ${styles.steps} ${
            provider === 'bkk' ? styles.stepsReduced : ''
          }`}
        >
          {getSteps(provider, t).map((step) => (
            <Card
              key={step.id}
              title={step.title}
              description={step.description}
              icon={step.icon}
            />
          ))}
        </div>
        <div className="d-flex mt40 jc-center">
          <button
            data-cy="complete-application"
            className={`p-btn p-btn--primary w100 m-auto ${styles.button}`}
            onClick={() => dispatch(redirectUserToApp(app.myPolicies))}
          >
            {' '}
            {t('page.nextstep.action', 'Complete application')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NextSteps;
