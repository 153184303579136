import { FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BottomOrRegularModal, Button, Input } from '@popsure/dirty-swan';
import { useHistory } from 'react-router';
import AnimateHeight from 'react-animate-height';

import { WithTitle } from 'components/questionnaire/questions/withTitle';
import { formatErrorMessageFromError } from 'reducers/request';
import SignIn from 'components/signIn';
import { isMobile } from 'util/isMobile';
import { answeredQuestion } from 'actions/questionnaire';
import { email as emailValidator } from 'validators';
import { AppState } from 'reducers';
import { getQuestionAfter } from 'reducers/questionnaire/order';

import { useTranslation } from '@getpopsure/i18n-react';
import { signOutUserAction } from 'actions/session';
import { isAuthenticatedAndSameEmail } from 'util/session/session';
import {
  getPolicies,
  isCustomerAffiliate,
  verifyCustomerEmail,
} from 'services/api';
import { routes } from 'routes';
import { getCustomerPolicyStatus } from './getCustomersPolicyStatus';

const EmailQuestion = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(
    useSelector((state: AppState) => state.questionnaire.email ?? '')
  );
  const nextQuestion = useSelector((state: AppState) =>
    getQuestionAfter(state.questionnaire, 'email')
  );
  const history = useHistory();
  const [displaySignInModal, setDisplaySignInModal] = useState(false);
  const [isExistingCustomer, setIsExistingCustomer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const valid = emailValidator(value);
  const [affiliateCheck, setAffiliateCheck] = useState({
    checkCurrentUser: true,
    isAffiliate: false,
  });

  const onSubmitForm = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    setLoading(true);
    setError(null);

    try {
      const {
        data: { affiliateExists },
      } = await isCustomerAffiliate(value);

      if (affiliateCheck.checkCurrentUser && affiliateExists) {
        setAffiliateCheck({
          checkCurrentUser: false,
          isAffiliate: true,
        });
        return;
      }

      if (await isAuthenticatedAndSameEmail(value)) {
        await checkForExistingPolicies();
        return;
      }

      dispatch(signOutUserAction());

      const { data } = await verifyCustomerEmail(value);

      if (data.userExists) {
        setIsExistingCustomer(true);
        setDisplaySignInModal(true);
      } else {
        dispatch(answeredQuestion('email', value));
        history.push(nextQuestion);
      }
    } catch (e) {
      setError(formatErrorMessageFromError(e));
    } finally {
      setLoading(false);
    }
  };

  const checkForExistingPolicies = async () => {
    const { data: policies } = await getPolicies();
    const policyStatus = getCustomerPolicyStatus(policies);

    if (policyStatus === 'PENDING_POLICIES') {
      dispatch(answeredQuestion('email', value, false));
      history.push(routes.blocker.alreadySubmittedAnApplication.path);
      return;
    }

    if (policyStatus === 'ACTIVE_POLICIES') {
      dispatch(answeredQuestion('email', value, false));
      history.push(routes.blocker.alreadyCovered.path);
      return;
    }

    dispatch(answeredQuestion('email', value));
  };

  return (
    <WithTitle
      title={t('page.question.email.title', 'What’s your email address?')}
    >
      <form onSubmit={onSubmitForm}>
        <div className="wmx6">
          <Input
            data-cy="email"
            placeholder={t('page.question.email.input.placeholder', 'Email')}
            type="email"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              setIsExistingCustomer(false);
            }}
            autoFocus={isMobile === false}
          />
          <AnimateHeight
            duration={500}
            height={affiliateCheck.isAffiliate ? 'auto' : 0}
          >
            {affiliateCheck.isAffiliate && (
              <p className="p-p p-notice--info mt16">
                {t(
                  'page.question.email.customerIsAffiliate',
                  'It seems like you’re an affiliate partner of Feather! Each customer needs to be signed up with their own individual address, otherwise the commission cannot be paid out correctly and on time. Please update the address in order to continue.'
                )}
              </p>
            )}
          </AnimateHeight>
          <Button
            data-cy="questionnaire-continue-button"
            className="mt24 wmn3"
            loading={loading}
            disabled={!valid}
          >
            {t('page.question.email.action', 'Continue')}
          </Button>
          {error !== null && (
            <>
              <p className="p-notice--danger mt16">{error}</p>
            </>
          )}
        </div>
      </form>
      {isExistingCustomer && (
        <BottomOrRegularModal
          title={t('modal.signin.title', 'Sign in to Feather')}
          isOpen={displaySignInModal}
          onClose={() => setDisplaySignInModal(false)}
        >
          <SignIn
            email={value}
            context="EXISTING_ACCOUNT"
            onAuthenticationSuccess={() => {
              setDisplaySignInModal(false);
              setTimeout(() => {
                onSubmitForm();
              }, 300);
            }}
          />
        </BottomOrRegularModal>
      )}
    </WithTitle>
  );
};

export default EmailQuestion;
