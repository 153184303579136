import { website } from '@getpopsure/private-constants';
import { Trans, useTranslation } from '@getpopsure/i18n-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routes';

const AlreadySubmittedAnApplication = () => {
  const { t } = useTranslation();

  return (
    <div className="p-body public-signup-center-element">
      <div className="wmx8 d-flex fd-column my0 mx-auto">
        <div className="p-h1 mt16" data-cy="blocker-title">
          {t(
            'page.blocker.alreadySubmittedAnApplication.title',
            'You already submitted an application'
          )}
        </div>
        <div className="p-p mt8">
          <Trans i18nKey="page.blocker.alreadySubmittedAnApplication.description">
            <p className="p-p mb8">
              Applications are usually processed by the provider within 1 week.
              If you need to modify your application or get a status update,
              please reach out to us.
            </p>
            <p className="p-p">
              Note that applying with multiple providers at the same time can
              lead to duplicate payments and delays in processing. If your
              previous application was canceled, you can continue below.
            </p>
          </Trans>
        </div>
        <div className="mt8 d-flex jc-start ai-center f-wrap">
          <a className="mt8 wmn3 ws2 mr8 p-btn--primary" href={website.support}>
            {t('page.blocker.contactus.action', 'Contact us')}
          </a>
          <Link
            data-cy="link-to-question"
            className="mt8 wmn2 p-btn p-btn--secondary-white"
            to={`${routes.questionnaire.path}/name`}
          >
            {t(
              'page.blocker.alreadySubmittedAnApplication.continue.label',
              'Continue'
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AlreadySubmittedAnApplication;
